import { useSearchParams, useLocation } from '@remix-run/react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { type ViewMode } from '#app/root'
import { type UserConfidential, type User } from '#types/api-client.types.gen'
import { isUserConfidential } from '#types/guards'

type InternalViewContextType = {
	isInternalView: boolean
	toggleView: () => void
	isAdmin: boolean
}

const InternalViewContext = createContext<InternalViewContextType | undefined>(
	undefined,
)

type InternalViewProviderProps = {
	children: React.ReactNode
	user?: User | UserConfidential
	initialViewMode: ViewMode
}

export function InternalViewProvider({
	children,
	user,
	initialViewMode,
}: InternalViewProviderProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const isAdmin = Boolean(
		user
			? isUserConfidential(user)
				? user.is_admin === true
				: false
			: false,
	)
	const location = useLocation()

	// Local state to make UI updates instant
	const [isInternalView, setIsInternalView] = useState(
		// Default to internal view for admins
		isAdmin && initialViewMode !== 'client',
	)

	// Sync URL params with state changes
	useEffect(() => {
		if (!isAdmin) {
			setIsInternalView(false)
			return
		}

		const newParams = new URLSearchParams(searchParams)
		if (!isInternalView) {
			newParams.set('view', 'client')
		} else {
			newParams.delete('view')
		}

		// Remove view=client if we're in the admin portal
		if (location.pathname.startsWith('/admin') && !isInternalView) {
			newParams.delete('view')
			setSearchParams(newParams, { replace: true })
			setIsInternalView(true)
		} else if (newParams.toString() !== searchParams.toString()) {
			// Update only if necessary
			setSearchParams(newParams, { replace: true })
		}
	}, [
		isInternalView,
		isAdmin,
		searchParams,
		setSearchParams,
		location.pathname,
	])

	const toggleView = () => {
		if (!isAdmin) {
			setIsInternalView(false)
			return
		}
		setIsInternalView((prev) => !prev)
	}

	return (
		<InternalViewContext.Provider
			value={{ isInternalView, toggleView, isAdmin }}
		>
			{children}
		</InternalViewContext.Provider>
	)
}

export function useInternalView() {
	const context = useContext(InternalViewContext)
	if (context === undefined) {
		throw new Error(
			'useInternalView must be used within an InternalViewContextProvider',
		)
	}
	return context
}
