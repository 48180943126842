import {
	type ErrorResponse,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react'
import { type ReactElement } from 'react'
import { type IconType } from 'react-icons/lib'
import {
	PiHandPalm,
	PiLockBold,
	PiMagnifyingGlass,
	PiWarning,
} from 'react-icons/pi'
import { getErrorMessage } from '#app/utils/misc'
import { useInternalView } from '#app/utils/providers/useInternalView'
import Hero from './hero'

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => ReactElement | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<p>
			{error.status} {error.data}
		</p>
	),
	statusHandlers,
	unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => ReactElement | null
}) {
	const error = useRouteError()
	const params = useParams()

	if (typeof document !== 'undefined') {
		console.error(error)
	}

	return (
		<div className="text-h2 container flex items-center justify-center p-20">
			{isRouteErrorResponse(error)
				? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
						error,
						params,
					})
				: unexpectedErrorHandler(error)}
		</div>
	)
}

export default function GenericErrorBoundary() {
	const error = useRouteError()
	console.error('ErrorBoundary.GenericErrorBoundary', error)

	const { isAdmin, isInternalView } = useInternalView()

	if (isRouteErrorResponse(error)) {
		// Handle 401 errors specially - redirect to login with the current path
		if (error.status === 401) {
			const currentPath =
				window.location.pathname + window.location.search
			window.location.href = `/login?redirectTo=${encodeURIComponent(currentPath)}`
			return null // Return null while redirecting
		}

		let errorData
		try {
			// Parse the JSON data from the Response
			errorData = JSON.parse(error.data)
		} catch {
			errorData = error.data
		}

		if (errorData?.error_type || errorData?.error) {
			const errorType = errorData.error_type || errorData.error

			// Special handling for authentication errors
			if (
				errorType === 'UnauthorizedError' ||
				errorType === 'AuthenticationError'
			) {
				const currentPath =
					window.location.pathname + window.location.search
				window.location.href = `/login?redirectTo=${encodeURIComponent(currentPath)}`
				return null // Return null while redirecting
			}

			return (
				<Hero
					icon={getErrorIcon(errorType)}
					title={getErrorTitle(error.status, errorType)}
					description={getErrorTypeMessage(
						errorType,
						errorData.message,
					)}
				/>
			)
		}

		return (
			<Hero
				icon={PiHandPalm}
				title="Unexpected Error"
				description={getErrorTypeMessage('Unknown', error.statusText)}
			/>
		)
	}

	// Handle unexpected errors
	return (
		<Hero
			icon={PiHandPalm}
			title="Unknown error"
			description="Something unexpected happened"
		>
			<div className="mt-4 flex items-center justify-center gap-x-6">
				{isAdmin && isInternalView ? (
					<a
						href="https://moonhub-internal.slack.com/archives/C050FLXNZEW"
						target="_blank"
						className="text-sm font-semibold text-gray-600 dark:text-gray-500"
						rel="noreferrer"
					>
						Contact support via slack #internal-product-feedback
					</a>
				) : (
					<p>Please contact your CSM or reach out via Slack</p>
				)}
			</div>
		</Hero>
	)
}

function getErrorTitle(status: number, errorType: string): string {
	switch (errorType) {
		case 'UnauthorizedError':
		case 'PermissionError':
		case 'PermissionDeniedError':
			return 'Access Denied'
		case 'NotFoundError':
			return 'Not Found'
		case 'ValidationError':
			return 'Invalid Input'
		default:
			return `Error ${status}`
	}
}

// Add this function to get the appropriate icon based on error type
function getErrorIcon(errorType: string): IconType {
	switch (errorType) {
		case 'UnauthorizedError':
		case 'PermissionError':
		case 'PermissionDeniedError':
			return PiLockBold
		case 'NotFoundError':
			return PiMagnifyingGlass
		case 'ValidationError':
			return PiWarning
		default:
			return PiHandPalm
	}
}

function getErrorTypeMessage(
	errorType: string,
	originalMessage: string,
): string {
	switch (errorType) {
		case 'UnauthorizedError':
		case 'PermissionError':
		case 'PermissionDeniedError':
			return "You don't have permission to access this resource."
		case 'NotFoundError':
			return "We couldn't find what you're looking for."
		case 'ValidationError':
			// For validation errors, we usually want to show the actual message
			return (
				originalMessage ||
				'The provided information is not valid. Please check your input and try again.'
			)
		case 'AuthenticationError':
			return 'Your session may have expired. Please sign in again.'
		case 'RateLimitError':
			return 'Too many requests. Please try again in a few minutes.'
		default:
			// For unknown error types, use a generic message
			return 'Something unexpected happened. Our team has been notified.'
	}
}
